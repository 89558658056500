#root {
  /* Modal Content */
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */ }
  #root .red {
    color: red; }
  #root .login-top-header {
    height: 80px;
    padding: 20px;
    /* background: #ea6954; */
    background: #ffffff;
    border-bottom: 1px solid #dbe0e9;
    /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); */ }
  #root .header-dark {
    background: #253b54;
    border-bottom: 1px solid #243a54; }
  #root .header-icon {
    float: left;
    cursor: pointer; }
    #root .header-icon img {
      width: 180px; }
  #root .login-top-button {
    padding: 10px;
    margin-right: 30px;
    cursor: pointer;
    font-size: 17px;
    color: white;
    float: right;
    text-decoration: none; }
  #root .login-top-button:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: blue;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }
  #root .signup-top-button {
    margin-right: 20px;
    float: right;
    padding: 10px 20px;
    background: white;
    border-radius: 10px;
    color: #22262a; }
    @media screen and (max-width: 768px) {
      #root .signup-top-button {
        display: none; } }
  #root .signup-top-button:hover {
    background: #f9f9f9;
    cursor: pointer;
    text-decoration: none; }
  #root .cover-image {
    width: 100%;
    height: 800px;
    position: absolute;
    opacity: 0.5; }
  #root .homepg1-container {
    padding: 60px 40px;
    margin-right: 0px;
    background: #141E30;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #243B55, #141E30);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #243B55, #141E30);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  #root .homepg1-container-left {
    text-align: center;
    padding: 100px 40px;
    -webkit-animation: slide-up-fade-in 1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: slide-up-fade-in 1s;
    /* Firefox < 16 */
    -ms-animation: slide-up-fade-in 1s;
    /* Internet Explorer */
    -o-animation: slide-up-fade-in-up-fade-inin 1s;
    /* Opera < 12.1 */
    animation: slide-up-fade-in 1s; }
  #root .homepg1-container-right {
    text-align: center; }
  #root .homepg1-title {
    text-align: left;
    font-size: 50px;
    color: white;
    font-weight: 300; }
  #root .homepg1-content {
    font-size: 30px;
    font-weight: 200;
    text-align: left;
    color: white; }
  #root .homepg1-rform-container {
    border-radius: 10px;
    float: center;
    width: 100%;
    height: 600px;
    background: white;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.3); }
  #root .custom-input {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    height: 54px; }
  #root .custom-input:focus {
    border: 1px solid #56b4ef;
    box-shadow: 0px 0px 3px 1px #c8def0; }
  #root .rform-container-title {
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 30px; }
  #root .reg-form-input-margin {
    margin-bottom: 20px; }
  #root .signup-btn {
    background-color: #22825d;
    border-radius: 4px;
    border: none;
    color: #fff;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    font-size: 18px;
    font-weight: 300; }
  #root .signup-btn:focus {
    outline: none; }
  #root .signup-btn:hover {
    background-color: #156f4c;
    color: white; }
  #root .signup-btn:disabled {
    background-color: #dddddd;
    color: white; }
  #root .display-mobile-sign-up {
    display: none;
    text-align: center; }
    #root .display-mobile-sign-up .signup-btn {
      width: 300px; }
  #root .rform-disclaimer {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    color: #6a737d; }
  @media screen and (max-width: 1100px) {
    #root .homepg1-rform-container {
      display: none; }
    #root .homepg1-container-left {
      padding: 50px 40px; }
    #root .display-mobile-sign-up {
      display: block; } }
  @media screen and (max-width: 768px) {
    #root .cover-image {
      display: none; }
    #root .homepg1-container {
      height: 100%; }
    #root .display-mobile-sign-up {
      display: block;
      margin-top: 0px; }
    #root .homepg1-container-right {
      padding: 20px;
      display: none; } }
  @media screen and (max-width: 480px) {
    #root .homepg1-container {
      padding: 20px 30px; }
    #root .homepg1-container-left {
      text-align: center;
      padding: 20px 10px; }
    #root .homepg1-title {
      text-align: left;
      font-size: 30px;
      margin-bottom: 20px;
      text-align: center; }
    #root .homepg1-content {
      font-size: 20px;
      font-weight: 300;
      text-align: center; }
    #root .display-mobile-sign-up {
      display: block;
      margin-top: 0px; } }
  #root .custom-input-container {
    position: relative; }
    #root .custom-input-container.has-success input {
      /* background: $bg; */
      border-color: #35be8b;
      border-width: 1px; }
      #root .custom-input-container.has-success input:focus {
        border-color: #35be8b;
        border-width: 1px;
        box-shadow: 0 0 5px 0 #35be8b; }
    #root .custom-input-container.has-warning input {
      /* background: $bg; */
      border-color: #f6a623;
      border-width: 1px; }
      #root .custom-input-container.has-warning input:focus {
        border-color: #f6a623;
        border-width: 1px;
        box-shadow: 0 0 5px 0 #f6a623; }
    #root .custom-input-container.has-error input {
      /* background: $bg; */
      border-color: #f25a6b;
      border-width: 1px; }
      #root .custom-input-container.has-error input:focus {
        border-color: #f25a6b;
        border-width: 1px;
        box-shadow: 0 0 5px 0 #f25a6b; }
  #root .custom-input {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ccc;
    outline: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    height: 54px; }
  #root .custom-input:focus {
    border: 1px solid #56b4ef;
    box-shadow: 0px 0px 3px 1px #c8def0; }
  #root .custom-input:invalid {
    border: 1px solid #f25a6b;
    box-shadow: 0px 0px 3px 1px #f25a6b; }
  #root .tooltiptext {
    opacity: 0;
    width: 220px;
    background-color: #272727;
    color: #fff;
    text-align: center;
    padding: 10px 5px;
    border-radius: 6px;
    visibility: hidden;
    font-weight: 300;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    /* Top Position */
    bottom: calc(100% + 10px);
    left: 50%;
    margin-left: -100px;
    /* Animation */
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
    -webkit-transition: all .15s ease-out;
    -moz-transition: all .15s ease-out;
    -ms-transition: all .15s ease-out;
    -o-transition: all .15s ease-out;
    transition: all .15s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28); }
  #root .show-tooltiptext {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  #root .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #272727 transparent transparent transparent; }
  #root .footer {
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 70px;
    border-top: 1px solid #e7ebee; }
    #root .footer p {
      font-size: 12px;
      margin-top: 20px;
      color: #7e8287; }
    @media screen and (max-width: 768px) {
      #root .footer {
        position: static; } }
  #root .privacy-anchor {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline; }
  #root .chatlist-header {
    padding: 20px;
    border-bottom: 1px solid #233b4c; }
    #root .chatlist-header .heading {
      color: #c2c2c2;
      font-size: 27px;
      font-weight: 300;
      display: inline-block; }
    #root .chatlist-header .add-icon {
      color: #fff;
      width: 24px;
      height: 24px;
      display: inline-block;
      float: right;
      margin-top: 12px;
      cursor: pointer; }
  #root .selected-chat-list .chatlist-item {
    background: #1c303e; }
  #root .chatlist-item {
    padding: 10px 16px 10px 16px;
    transition: background-color 0.5s;
    cursor: pointer;
    border-bottom: 1px solid #233b4c; }
    #root .chatlist-item .avatar-block {
      display: inline-block;
      vertical-align: top; }
    #root .chatlist-item .text-block {
      display: inline-block;
      padding-left: 20px;
      width: calc(100% - 150px); }
      #root .chatlist-item .text-block .conv-name {
        font-weight: 300;
        font-size: 23px;
        color: #f3f3f3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      #root .chatlist-item .text-block .text-message {
        font-size: 16px;
        color: #bbbbbb;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    #root .chatlist-item .timestamp-block {
      display: inline-block;
      float: right; }
      #root .chatlist-item .timestamp-block .ts {
        font-size: 14px;
        margin-top: 8px;
        color: #a8b5ae; }
      #root .chatlist-item .timestamp-block .badge-container {
        float: right; }
  #root .chatlist-item:hover {
    background: #1c303e; }
  #root .emoji-block-in-text-chatlist img {
    width: 19px;
    height: 19px; }
  #root .ppl-connect-img {
    text-align: center;
    padding-top: 205px; }
  #root .ppl-connect-img-text {
    font-size: 20px;
    margin-top: 20px;
    color: #294356; }
  #root .chat-detail-header {
    padding: 20px;
    border-bottom: 1px solid #e6e6e6; }
    #root .chat-detail-header .title {
      display: inline-block;
      font-size: 28px;
      font-weight: 300; }
  #root .online-status {
    margin-right: 20px;
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #b7b7b7;
    vertical-align: middle;
    margin-top: 0px; }
  #root .is-online {
    background-color: #55b976; }
  #root .emoji-block-in-text-message img {
    width: 25px;
    height: 25px; }
  #root .chat-body {
    height: calc(100% - 83px);
    padding-top: 3px; }
  #root .messages-body {
    height: calc(100% - 53px);
    padding: 0px 25px;
    overflow: auto; }
    @media screen and (max-width: 450px) {
      #root .messages-body {
        padding: 0px 10px; } }
  #root .chat-text-container {
    position: relative;
    padding: 3px 47px 3px 1px;
    background: #ffffff;
    height: 53px;
    border: 1px solid #b7b7b7;
    border-radius: 8px; }
  #root .chat-text-icons {
    height: 15px;
    position: absolute;
    right: 10px;
    top: 14px; }
  #root .empty-msgs-img {
    text-align: center;
    padding-top: 20%; }
  #root .empty-msgs-txt {
    margin-top: 10px;
    font-size: 21px;
    color: #909193; }
  #root .messages-wrapper {
    font-size: 15px;
    padding-top: 10px;
    position: relative;
    border-top: 0 none; }
  #root .message-ts {
    text-align: right;
    font-size: 13px;
    color: #9b9b9f; }
  #root .message-date {
    text-align: center;
    margin: 10px 0px 15px 0px;
    color: #757575;
    font-size: 13px; }
  #root .message {
    border-radius: 20px 20px 20px 20px;
    margin: 0 15px 10px;
    padding: 15px 20px;
    position: relative;
    word-wrap: break-word; }
  #root .message.to {
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    margin-left: 60%;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      #root .message.to {
        margin-left: 40%; } }
    @media screen and (max-width: 450px) {
      #root .message.to {
        margin-left: 30%; } }
  #root .message.from {
    border: 1px solid #dddddd;
    background: #fbfbfb;
    color: #363636;
    margin-right: 60%;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      #root .message.from {
        margin-right: 40%; } }
    @media screen and (max-width: 450px) {
      #root .message.from {
        margin-right: 30%; } }
  #root .message.to + .message.to,
  #root .message.from + .message.from {
    margin-top: -7px; }
  #root .custom-modal {
    visibility: hidden;
    transition: visibility 0.1s linear;
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-animation: fadeout 0.1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 0.1s;
    /* Firefox < 16 */
    -ms-animation: fadeout 0.1s;
    /* Internet Explorer */
    -o-animation: fadeout 0.1s;
    /* Opera < 12.1 */
    animation: fadeout 0.1s; }
  #root .display-modal {
    display: block;
    visibility: visible;
    opacity: 1;
    -webkit-animation: fadein 0.1s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.1s;
    /* Firefox < 16 */
    -ms-animation: fadein 0.1s;
    /* Internet Explorer */
    -o-animation: fadein 0.1s;
    /* Opera < 12.1 */
    animation: fadein 0.1s; }
    #root .display-modal .modal-content {
      -webkit-animation: slide-down-fade-in 0.3s;
      /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: slide-down-fade-in 0.3s;
      /* Firefox < 16 */
      -ms-animation: slide-down-fade-in 0.3s;
      /* Internet Explorer */
      -o-animation: slide-down-fade-in 0.3s;
      /* Opera < 12.1 */
      animation: slide-down-fade-in 0.3s; }
  #root .modal-content {
    background-color: #fefefe;
    margin: auto;
    border: 1px solid #888; }
  #root .modal-anim {
    opacity: 0; }
  #root .hide-modal-box {
    -webkit-animation: slide-up-fade-out 0.3s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: slide-up-fade-out 0.3s;
    /* Firefox < 16 */
    -ms-animation: slide-up-fade-out 0.3s;
    /* Internet Explorer */
    -o-animation: slide-up-fade-out 0.3s;
    /* Opera < 12.1 */
    animation: slide-up-fade-out 0.3s; }
  #root .modal-lg {
    width: 80%; }
    @media screen and (max-width: 768px) {
      #root .modal-lg {
        width: 90%; } }
  #root .modal-md {
    width: 40%; }
    @media screen and (max-width: 1024px) {
      #root .modal-md {
        width: 60%; } }
    @media screen and (max-width: 768px) {
      #root .modal-md {
        width: 80%; } }
  #root .modal-sm {
    width: 35%; }
    @media screen and (max-width: 1024px) {
      #root .modal-sm {
        width: 45%; } }
    @media screen and (max-width: 768px) {
      #root .modal-sm {
        width: 80%; } }
  #root .input-box {
    padding: 12px; }
    #root .input-box .search-icon {
      width: 21px;
      height: 21px;
      display: inline-block;
      margin-right: 25px;
      margin-left: 5px;
      vertical-align: sub; }
    #root .input-box .search-input {
      display: inline-block;
      width: 85%;
      border: none;
      background: #fefefe;
      outline: none;
      height: 28px;
      font-size: 23px;
      font-weight: 300; }
  @media screen and (max-width: 480px) {
    #root .input-box .search-input {
      width: 75%; } }
  #root .user-list {
    max-height: 323px;
    overflow: auto; }
  #root .hover-user .userlist-item {
    background: #dedede; }
  #root .no-matches {
    margin: 30px auto;
    text-align: center; }
  #root .user-loading-spinner {
    height: 200px; }
  #root .userlist-item {
    padding: 10px 16px 10px 16px;
    transition: background-color 0.5s;
    cursor: pointer;
    border-bottom: 1px solid #dedede; }
    #root .userlist-item .avatar-block {
      display: inline-block;
      vertical-align: top; }
    #root .userlist-item .text-block {
      display: inline-block;
      padding-left: 20px;
      width: calc(100% - 150px); }
      #root .userlist-item .text-block .conv-name {
        font-weight: 300;
        font-size: 20px;
        color: #2b2b2b;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
      #root .userlist-item .text-block .text-message {
        font-size: 16px;
        color: #777777;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
  #root .userlist-item:hover {
    background: #dedede; }
  #root .emoji-picker {
    background: #FFF;
    position: absolute;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.32);
    border-radius: 5px;
    height: 276px;
    width: 348px;
    z-index: 99;
    transition: all .15s ease-in-out;
    display: none;
    opacity: 0;
    margin-top: -286px;
    top: 0;
    right: 2px;
    overflow: hidden; }
  #root .show-emoji-picker {
    display: block;
    opacity: 1; }
  #root .smiley-icon {
    width: 20px;
    height: 20px;
    cursor: pointer; }
  #root .chat-text-field {
    display: inline-block;
    width: 100%;
    outline: none;
    border: 0px;
    min-height: 47px;
    font-size: 18px;
    padding: 10px;
    border-radius: 0px 7px 7px 0px;
    background: white;
    overflow: hidden; }
    #root .chat-text-field img {
      height: 24px;
      width: 24px; }
  #root .pictures-page {
    text-align: center;
    padding-top: 150px;
    height: 100%;
    background-color: #7d373e; }
  #root .big-camera-icon {
    width: 280px;
    height: 280px;
    color: #dadada;
    margin: auto; }
  #root .badge-wrapper {
    margin-top: 9px;
    width: 20px;
    height: 20px;
    background-color: #f25a6b;
    border-radius: 50px;
    text-align: center; }
  #root .badge-number {
    position: relative;
    top: 1px;
    font-size: 12px;
    color: white;
    font-weight: 300; }
  #root .page-not-found {
    height: 100%;
    padding-top: 150px;
    text-align: center;
    background: #f25a6b; }
    #root .page-not-found .hand-icon {
      height: 300px;
      width: 300px;
      margin: 0px auto;
      color: #fdfdfd; }
    #root .page-not-found .hand-text {
      font-size: 24px;
      margin-top: 25px;
      color: #fdfdfd; }
  #root .privacy-policy .policy-header {
    height: 135px;
    background: #42275a;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #734b6d, #42275a);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #734b6d, #42275a);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 35px; }
  #root .privacy-policy .policy-text {
    font-size: 40px;
    color: white;
    font-weight: 300;
    margin-left: 35px; }
  #root .privacy-policy .policy-container {
    padding: 50px;
    background: #f1f1f1; }
  #root .app {
    font-size: inherit;
    font-family: 'Lato', sans-serif;
    height: 100%;
    /* @include my-padding-mixin(20px); */ }
  #root .hamburger button:first-child {
    display: none !important; }
    @media screen and (max-width: 768px) {
      #root .hamburger button:first-child {
        display: inline-block !important; } }
  #root .hamburger h1 {
    font-weight: 100 !important; }
  #root .sidenav {
    height: 100%;
    width: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #1c3140, #192c3a);
    /* box-shadow: inset -5px 0 10px 0 rgba(0, 0, 0, 0.2); */
    background: -webkit-linear-gradient(to bottom, #1c3140, #192c3a);
    /* Chrome 10-25, Safari 5.1-6 */
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 40px; }
    @media screen and (max-width: 768px) {
      #root .sidenav {
        width: 0px; } }
  #root .sidenav-anim-appear {
    width: 0px; }
  #root .row {
    margin-right: 0px; }
  #root .sidenav-icon {
    color: white;
    width: 30px;
    height: 30px;
    margin: 5px auto; }
  #root .selected {
    color: red; }
  #root .main {
    margin-left: 60px;
    opacity: 1;
    transition: margin-left .4s, opacity .2s;
    height: 100%;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      #root .main {
        margin-left: 0px; } }
  #root .main-anim-appear {
    margin-left: 0px;
    opacity: 0; }
  #root .max-height {
    height: 100%; }
  #root .no-margin {
    margin: 0px;
    padding: 0px; }
  #root .svg-icon {
    color: inherit;
    fill: currentColor;
    width: inherit;
    height: inherit; }
  #root .logout-icon {
    position: absolute;
    bottom: 0;
    left: 15px; }
  #root .font-20 {
    font-size: 20px; }
  #root .font-16 {
    font-size: 16px; }
  #root ::-webkit-scrollbar {
    width: 4px; }
  #root ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  #root ::-webkit-scrollbar-thumb {
    background: #888; }
  #root ::-webkit-scrollbar-thumb:hover {
    background: #555; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadein {
  #root 0% {
    opacity: 0; }
  #root 100% {
    opacity: 1; } }

@-o-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeout {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-moz-keyframes fadeout {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeout {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-ms-keyframes fadeout {
  #root 0% {
    opacity: 1; }
  #root 100% {
    opacity: 0; } }

@-o-keyframes fadeout {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-down-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, -40px); }
  100% {
    opacity: 1;
    transform: translate(0px, 0px); } }

@-moz-keyframes slide-down-fade-in {
  0% {
    opacity: 0;
    -moz-transform: translate(0px, -40px); }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px); } }

@-webkit-keyframes slide-down-fade-in {
  0% {
    opacity: 0;
    -webkit-transform: translate(0px, -40px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px); } }

@-o-keyframes slide-down-fade-in {
  0% {
    opacity: 0;
    -o-transform: translate(0px, -40px); }
  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px); } }

@-ms-keyframes slide-down-fade-in {
  #root 0% {
    opacity: 0;
    -ms-transform: translate(0px, -40px); }
  #root 100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px); } }

@keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    transform: translate(0px, 0px); }
  100% {
    opacity: 0;
    transform: translate(0px, -40px); } }

@-moz-keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    -moz-transform: translate(0px, 0px); }
  100% {
    opacity: 1;
    -moz-transform: translate(0px, -40px); } }

@-webkit-keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px); }
  100% {
    opacity: 0;
    -webkit-transform: translate(0px, -40px); } }

@-o-keyframes slide-up-fade-out {
  0% {
    opacity: 1;
    -o-transform: translate(0px, 0px); }
  100% {
    opacity: 0;
    -o-transform: translate(0px, -40px); } }

@-ms-keyframes slide-up-fade-out {
  #root 0% {
    opacity: 1;
    -ms-transform: translate(0px, 0px); }
  #root 100% {
    opacity: 0;
    -ms-transform: translate(0px, -40px); } }
  #root .app {
    font-size: inherit;
    font-family: 'Lato', sans-serif;
    height: 100%;
    /* my-padding-mixin(20px); */ }
  #root .height-max {
    height: 100%; }
  #root .no-margin {
    margin: 0px;
    padding: 0px; }
  #root .messages-divider {
    border-right: 1px solid #cdcdcd;
    padding-right: 0px;
    background: #fbfbfb; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }
  #root .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite; }
  #root .template-container {
    height: calc(100% - 80px); }
  #root .login-container {
    padding-right: 0px; }
    #root .login-container input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 100; }
    #root .login-container input::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 100; }
    #root .login-container input:-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 100; }
    #root .login-container input:-moz-placeholder {
      /* Firefox 18- */
      font-weight: 100; }
    @media screen and (max-width: 768px) {
      #root .login-container {
        margin-top: 40px;
        padding: 0px 40px; } }
  #root .login-form-heading {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 200;
    text-align: center; }
  #root .login-btn-container {
    text-align: center; }
  #root .login-btn {
    background-color: #22825d;
    border-radius: 4px;
    border: none;
    color: #fff;
    letter-spacing: 1px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 80%;
    font-weight: 200;
    margin-top: 20px; }
  #root .login-btn:focus {
    outline: none; }
  #root .login-btn:hover {
    background-color: #156f4c;
    color: white; }
  #root .login-btn:disabled {
    background-color: #dddddd;
    color: white; }
  #root .regform-heading {
    font-size: 40px;
    font-weight: 200;
    text-align: center; }
  #root .regform-sub-heading {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: 200;
    text-align: center; }
  #root .reg-button {
    width: 80%; }
  @media screen and (max-width: 768px) {
    #root .registration-container {
      padding: 0px 30px; } }
  #root .signin-container {
    padding-right: 0px; }
    #root .signin-container input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-weight: 100; }
    #root .signin-container input::-moz-placeholder {
      /* Firefox 19+ */
      font-weight: 100; }
    #root .signin-container input:-ms-input-placeholder {
      /* IE 10+ */
      font-weight: 100; }
    #root .signin-container input:-moz-placeholder {
      /* Firefox 18- */
      font-weight: 100; }
    @media screen and (max-width: 768px) {
      #root .signin-container {
        margin-top: 40px;
        padding: 0px 40px; } }
  #root .flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  #root .messages-divider {
    border-right: 1px solid #cdcdcd;
    padding-right: 0px;
    background: #294356; }
  #root .no-convs {
    text-align: center;
    padding-top: 180px;
    font-size: 16px;
    cursor: pointer;
    background-color: #294356;
    height: 100%; }
  #root .no-convs-text {
    font-weight: 300;
    font-size: 24px;
    margin-top: 10px;
    color: #dadada;
    padding-left: 20px; }
  #root .add-chat-icon {
    width: 280px;
    height: 280px;
    color: #dadada;
    margin: auto; }

html, body {
  height: 100%; }
